import "./components/_header";
import "./components/_cloud";
import "./components/_tree";
import "./components/_star";
import "./components/_accordion";
import "./components/_tab";
import "./components/_expand-vertical";
import "./components/_modal";
import "./components/_input-mask";

import "./components/_komanda-slider";
import "./components/not-found/_not-found";
import "./components/afisha/_afisha";
import "./components/support/support";
import "./components/otcheti/_otcheti";
import "./components/partner/_partner";
import "./components/profile/_profile";

import "./components/_loader";

import "./components/glavnaya";
