// .add(
//   {
//     targets: panelkaWrapperLeft,
//     bottom: ["-100%", "18%"],
//     left: ["0%", "0%"],
//     translateY: ["50%", "50%"],
//     // translateX: ["-50%", "-50%"],
//     duration: 300,
//     easing: "linear",
//   },
//   "-=600"
// )
// .add(
//   {
//     targets: panelkaWrapperRight,
//     bottom: ["-100%", "20%"],
//     right: ["0%", "0%"],
//     translateY: ["50%", "50%"],
//     // translateX: ["50%", "50%"],
//     duration: 300,
//     easing: "linear",
//   },
//   "-=500"
// )
// .add(
//   {
//     targets: yellowBgWrapper,
//     bottom: ["-100%", "-5%"],
//     // translateX: ["-50%", "-50%"],
//     duration: 300,
//     easing: "linear",
//   },
//   "-=400"
// )
// .add(
//   {
//     targets: unicycleProfileGirlWrapper,
//     bottom: ["-100%", `${panelkaWrapperLeft.offsetHeight * 1.03}px`],
//     left: ["16%", "16%"],
//     translateY: ["50%", "50%"],
//     translateX: ["-50%", "-50%"],
//     rotate: [-10, -10],
//     duration: 300,
//     easing: "linear",
//   },
//   "-=300"
// )
// .add(
//   {
//     targets: unicycleProfileBoyWrapper,
//     bottom: ["-100%", `${panelkaWrapperLeft.offsetHeight}px`],
//     left: ["24%", "24%"],
//     translateY: ["50%", "50%"],
//     translateX: ["-50%", "-50%"],
//     rotate: [-10, -10],
//     duration: 300,
//     easing: "linear",
//   },
//   "-=300"
// );
// .add(
//   {
//     targets: elephantWrapper,
//     bottom: ["-20%", "59%"],
//     right: ["15%", "15%"],
//     translateY: ["50%", "50%"],
//     translateX: ["50%", "50%"],
//     scale: [0.5, 0.5],
//     duration: 500,
//     easing: "linear",
//   },
//   8500
// )
// .add(
//   {
//     targets: yellowBgWrapper,
//     bottom: "15%",
//     duration: 500,
//     easing: "linear",
//   },
//   8500
// )
// .add(
//   {
//     targets: childrensWrapper,
//     bottom: ["-30%", "18%"],
//     left: ["50%", "50%"],
//     translateX: ["-50%", "-50%"],
//     translateY: ["50%", "50%"],
//     scale: [0.5, 0.5],
//     duration: 500,
//     easing: "linear",
//   },
//   8500
// )
// .add(
//   {
//     targets: ropeWrapper,
//     opacity: [0, 1],
//     duration: 200,
//     easing: "linear",
//   },
//   9100
// )
// .add(
//   {
//     targets: unicycleProfileBoyWrapper,
//     bottom: {
//       value: "63%",
//       duration: 100,
//     },
//     left: {
//       value: "68%",
//       duration: 900,
//     },
//     easing: "linear",
//   },
//   9300
// )
// .add(
//   {
//     targets: unicycleProfileBoyWrapper,
//     left: "79%",
//     bottom: "71%",
//     duration: 300,
//     easing: "linear",
//   },
//   10300
// )
// .add(
//   {
//     targets: unicycleProfileBoyWrapper,
//     left: "110%",
//     bottom: "45%",
//     duration: 500,
//     easing: "linear",
//   },
//   10600
// )
// .add(
//   {
//     targets: unicycleProfileGirlWrapper,
//     bottom: {
//       value: "63%",
//       duration: 100,
//     },
//     left: {
//       value: "60.5%",
//       duration: 900,
//     },

//     easing: "linear",
//   },
//   9300
// )
// .add(
//   {
//     targets: unicycleProfileGirlWrapper,
//     left: "67%",
//     duration: 100,
//     easing: "linear",
//   },
//   10300
// )
// .add(
//   {
//     targets: unicycleProfileGirlWrapper,
//     left: "79%",
//     bottom: "71%",
//     duration: 200,
//     easing: "linear",
//   },
//   10500
// )
// .add(
//   {
//     targets: unicycleProfileGirlWrapper,
//     left: "110%",
//     bottom: "47%",
//     duration: 500,
//     easing: "linear",
//   },
//   10750
// )
// .add(
//   {
//     targets: elephantWrapper,
//     bottom: "71%",
//     right: "19%",
//     easing: "linear",
//     duration: 100,
//   },
//   10600
// )
// .add(
//   {
//     targets: elephantWrapper,
//     bottom: "45%",
//     right: "-20%",
//     easing: "linear",
//     duration: 500,
//   },
//   10750
// )
// .add(
//   {
//     targets: ropeWrapper,
//     opacity: 0,
//     duration: 200,
//     easing: "linear",
//   },
//   10400
// )
// .add(
//   {
//     targets: childrensWrapper,
//     bottom: "-30%",
//     duration: 500,
//     easing: "linear",
//   },
//   11000
// )
// .add(
//   {
//     targets: panelkaWrapperRight,
//     bottom: "-60%",
//     duration: 500,
//     easing: "linear",
//   },
//   11300
// )
// .add(
//   {
//     targets: panelkaWrapperLeft,
//     bottom: "-65%",
//     duration: 500,
//     easing: "linear",
//   },
//   11500
// )
// .add(
//   {
//     targets: yellowBgWrapper,
//     bottom: "-30%",
//     duration: 500,
//     easing: "linear",
//   },
//   11500
// )
// .add(
//   {
//     targets: yellowBgWrapper,
//     bottom: ["-30%", "15%"],
//     left: ["0%", "0%"],
//     right: ["0%", "0%"],
//     width: "100%",
//     translateY: ["50%", "50%"],
//     duration: 300,
//     easing: "linear",
//   },
//   7500
// )
// .add(
//   {
//     targets: treeWrapper1,
//     translateY: ["50%", "50%"],
//     translateX: ["-50%", "-50%"],
//     bottom: ["-35%", "3%"],
//     left: ["5%", "5%"],
//     duration: 300,
//     easing: "linear",
//   },
//   7500
// )
// .add(
//   {
//     targets: treeWrapper2,
//     translateY: ["50%", "50%"],
//     translateX: ["-50%", "-50%"],
//     bottom: ["-35%", "-10%"],
//     left: ["23%", "23%"],
//     scale: [0.8, 0.8],
//     duration: 300,
//     easing: "linear",
//   },
//   7500
// )
// .add(
//   {
//     targets: treeWrapper3,
//     translateY: ["50%", "50%"],
//     translateX: ["-50%", "-50%"],
//     bottom: ["-35%", "2%"],
//     left: ["40%", "40%"],
//     duration: 300,
//     easing: "linear",
//   },
//   7500
// )
// .add(
//   {
//     targets: treeWrapper4,
//     translateY: ["50%", "50%"],
//     translateX: ["-50%", "-50%"],
//     bottom: ["-35%", "-7%"],
//     left: ["53%", "53%"],
//     duration: 300,
//     easing: "linear",
//   },
//   7500
// )
// .add(
//   {
//     targets: treeWrapper5,
//     translateY: ["50%", "50%"],
//     translateX: ["-50%", "-50%"],
//     bottom: ["-35%", "13%"],
//     left: ["67%", "67%"],
//     scale: [0.95, 0.95],
//     duration: 300,
//     easing: "linear",
//   },
//   7500
// )
// .add(
//   {
//     targets: treeWrapper6,
//     translateY: ["50%", "50%"],
//     translateX: ["-50%", "-50%"],
//     bottom: ["-35%", "-13%"],
//     left: ["77%", "77%"],
//     duration: 300,
//     easing: "linear",
//   },
//   7500
// )
// .add(
//   {
//     targets: treeWrapper7,
//     translateY: ["50%", "50%"],
//     translateX: ["-50%", "-50%"],
//     bottom: ["-35%", "1%"],
//     left: ["90%", "90%"],
//     scale: [1.1, 1.1],
//     duration: 300,
//     easing: "linear",
//   },
//   7500
// )
// .add(
//   {
//     targets: treeWrapperAll,
//     bottom: "-35%",
//     duration: 300,
//     easing: "linear",
//   },
//   8200
// )
// .add(
//   {
//     targets: yellowBgWrapper,
//     bottom: "-30%",
//     duration: 300,
//     easing: "linear",
//   },
//   8200
// );

document.addEventListener("DOMContentLoaded", function () {
  const section = document.querySelector(".section-5");

  if (section != null) {
    const unicycleWrapper = section.querySelector(".unicycle__wrapper");
    const unicycleInner = section.querySelector(".unicycle__inner");
    const unicycle = section.querySelector(".unicycle");

    const treeBlock = document.querySelector("#indexPage > .tree__block");

    const neCirkTimeline = anime
      .timeline({ autoplay: false })
      .add({
        delay: window.pageYOffset + section.getBoundingClientRect().top - 500,
        targets: unicycleWrapper,
        bottom: ["-50%", "60%"],
        left: ["25%", "25%"],
        translateX: ["-50%", "-50%"],
        translateY: ["50%", "50%"],
        duration: 500,
        easing: "linear",
      })
      .add(
        {
          targets: unicycleWrapper,
          left: "-50%",
          duration: 300,
          easing: "linear",
        },
        "+=400"
      );

    anime({
      targets: unicycle,
      translateX: ["20%", "-15%"],
      translateY: ["-3%", "3%"],
      duration: 4000,
      loop: true,
      direction: "alternate",
      easing: "easeInOutQuad",
    });
    anime({
      targets: unicycleInner,
      rotate: [-5, 10],
      translateX: ["-3%", "3%"],
      duration: 1500,
      loop: true,
      direction: "alternate",
      easing: "easeInOutQuad",
    });

    window.addEventListener("scroll", () => {
      neCirkTimeline.seek(window.pageYOffset);
    });
  }
});
