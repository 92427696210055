// const body = document.getElementsByTagName("body")[0];
// const loading = document.querySelector("#loading");

// setTimeout(() => {
//   loading.style.display = "none";
// }, 500);

// // if (
// //   document.body.readyState === "ready" ||
// //   document.body.readyState === "complete"
// // ) {
// //   loading.style.display = "none";
// //   console.log("hah");
// // }

// // function onReady(callback) {
// //   var intervalId = window.setInterval(function () {
// //     if (document.getElementsByTagName("body")[0] !== undefined) {
// //       window.clearInterval(intervalId);
// //       callback.call(this);
// //     }
// //   }, 1000);
// // }

// // function setVisible(selector, visible) {
// //   document.querySelector(selector).style.display = visible ? "block" : "none";
// // }

// // onReady(function () {
// //   setVisible(".page", true);
// //   setVisible("#loading", false);
// // });
