document.addEventListener("DOMContentLoaded", function () {
  const profileNav = document.querySelectorAll(".profile__nav .nav__item");
  const profileBlock = document.querySelector(".profile__block");
  const subscriptionBlock = profileBlock.querySelector(".subscription__block");
  const profileData = profileBlock.querySelector(".profile__data");
  const profileBlockRight = profileBlock.querySelector(".profile__block-right");

  if (profileNav !== null && profileBlock !== null) {
    const getCurrentId = () => {
      let currentId = null;
      for (let i = 0; i < profileNav.length; i++) {
        if (profileNav[i].className.match(/\bis-active\b/)) {
          currentId = i;
        }
      }
      return currentId;
    };

    const getScrollTarget = (navItem) => {
      const navContent = navItem.innerHTML.toLowerCase().split(" ").join("");
      switch (navContent) {
        case "подписка":
          profileBlock.querySelector(".subscription__block").scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
          break;
        case "данныепрофиля":
          profileBlock.querySelector(".profile__data").scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
          break;

        case "транзакции":
          profileBlock.querySelector(".transaction__block").scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
          break;

        default:
          break;
      }
    };

    for (let i = 0; i < profileNav.length; i++) {
      profileNav[i].addEventListener("click", function () {
        const current = profileNav[getCurrentId()];
        const next = profileNav[i];

        current.classList.remove("is-active");
        next.classList.add("is-active");

        getScrollTarget(next);
      });
    }

    const dates = document.querySelectorAll(".profile__section input.date");
    const amounts = document.querySelectorAll(".profile__section input.amount");
    const curentYear = new Date().getFullYear().toString();
  }

  if (window.innerWidth < 1024) {
    subscriptionBlock.style["margin-bottom"] = `${
      profileData.offsetHeight + 60
    }px`;
    profileBlockRight.style["top"] = `${subscriptionBlock.offsetHeight + 30}px`;
  } else {
    subscriptionBlock.style["margin-bottom"] = "32px";
  }

  window.addEventListener(
    "resize",
    function (event) {
      if (window.innerWidth < 1024) {
        subscriptionBlock.style["margin-bottom"] = `${
          profileData.offsetHeight + 60
        }px`;
        profileBlockRight.style["top"] = `${
          subscriptionBlock.offsetHeight + 30
        }px`;
      } else {
        subscriptionBlock.style["margin-bottom"] = "32px";
      }
    },
    true
  );
});
